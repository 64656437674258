<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
          :isShow="isShow"
          :btn="btn"
          :strForm="qutaForm"
          @openEdit="openEdit"
          @closeEdit="closeEdit"
          @submit="submit('qutaForm')"
      />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrint" v-if="qutaForm.status === 2">
          打印
        </el-button>
        <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExport" v-if="qutaForm.status === 2">
          下载PDF
        </el-button>
        <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExport1" plain
                   v-if="qutaForm.status === 2"
        >下载EXCEL
        </el-button>
        <el-button size="small" type="warning" @click="generateRqta" v-if="qutaForm.status === 2 && !rqtaFlag">
          <span>生成核价单</span>
        </el-button>
        <el-button size="small" type="warning" @click="generateRqta" v-if="qutaForm.status === 2 && rqtaFlag">
          <span>跳转核价单</span>
        </el-button>
      </div>
    </div>

    <el-form ref="qutaForm" :model="qutaForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="委托打样单号" prop="requ_no">
                <el-input disabled maxlength="10" v-model="qutaForm.requ_no" placeholder="请填写委托打样单号"
                          show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="报价单号" prop="quta_no">
                <el-input disabled maxlength="10" v-model="qutaForm.quta_no" placeholder="请填写报价单号"
                          show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品工单号" prop="smpl_no_list">
                <!-- <el-input disabled maxlength="10" v-model="qutaForm.smpl_no" placeholder="请填写样品工单号" show-word-limit>
                </el-input> -->
                <el-select @change="selectSmplNoChange" v-model="smplList" multiple placeholder="请选择">
                  <el-option v-for="item in smplCopyList" :key="item.smpl_id" :label="item.smpl_no"
                             :value="item.smpl_no"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="样品名称" prop="smpl_name">
                <el-input disabled maxlength="10" v-model="qutaForm.smpl_name" placeholder="请填写样品名称"
                          show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品含税价格" prop="quta_total">
                <el-input
                    disabled
                    maxlength="10"
                    v-model="qutaForm.profitForm.priceIndTax"
                    placeholder="请填写样品含税价格"
                    show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="含运费" prop="smpl_frei_flag">
                <el-radio disabled v-model="qutaForm.smpl_frei_flag" label="1">是</el-radio>
                <el-radio disabled v-model="qutaForm.smpl_frei_flag" label="0">否</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="打样部门" prop="belo_dept_name">
                <el-input disabled maxlength="10" v-model="qutaForm.belo_dept_name" placeholder="请填写打样部门"
                          show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="样品类型" prop="smpl_type">
                <el-select disabled v-model="qutaForm.smpl_type" placeholder="请选择样品类型" size="small" clearable>
                  <el-option v-for="item in smplType" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="实际交样日期" prop="smpl_deil_time">
                <el-date-picker disabled v-model="qutaForm.smpl_deil_time" type="date"
                                placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户货号" prop="smpl_cust_no">
                <el-input maxlength="10" disabled v-model="qutaForm.smpl_cust_no" placeholder="请填写客户货号"
                          show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="产品尺寸" prop="smpl_spec">
                <el-input maxlength="10" disabled v-model="qutaForm.smpl_spec" placeholder="请填写产品尺寸"
                          show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="起订量" prop="smpl_moq">
                <el-input maxlength="10" disabled v-model="qutaForm.smpl_moq" placeholder="请填写起订量"
                          show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="皮壳重量" prop="smpl_skin_weight">
                <el-input disabled maxlength="10" v-model="qutaForm.smpl_skin_weight" placeholder="请填写皮壳重量"
                          show-word-limit>
                  <template slot="append">g</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="样品重量" prop="smpl_weight">
                <el-input disabled maxlength="10" v-model="qutaForm.smpl_weight" placeholder="请填写样品重量"
                          show-word-limit>
                  <template slot="append">g</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="纸箱长度" prop="smpl_box_l">
                <el-input disabled maxlength="10" v-model="qutaForm.smpl_box_l" placeholder="请填写纸箱长度"
                          show-word-limit>
                  <template slot="append">cm</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="塑料袋长度" prop="smpl_bag_l">
                <el-input disabled maxlength="10" v-model="qutaForm.smpl_bag_l" placeholder="请填写塑料袋长度"
                          show-word-limit>
                  <template slot="append">cm</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="8">
          <el-form-item label="样品图片">
            <el-upload drag disabled class="avatar-uploader" :action="uploadUrl" :data="dataBody"
                       :show-file-list="false">
              <imgPopover v-if="qutaForm.imge_id" :imgeUrl="qutaForm.imge_url"
                          :imgeUrlO="qutaForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
              <!-- <i
                v-else
                class="el-icon-plus avatar-uploader-icon vg_pointer"
                :class="{ vd_cussor: isShow }"
              ></i> -->
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="纸箱宽度" prop="smpl_box_w">
            <el-input disabled maxlength="10" v-model="qutaForm.smpl_box_w" placeholder="请填写纸箱宽度"
                      show-word-limit>
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="塑料袋宽度" prop="smpl_bag_w">
            <el-input disabled maxlength="10" v-model="qutaForm.smpl_bag_w" placeholder="请填写塑料袋宽度"
                      show-word-limit>
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="装箱数" prop="smpl_qpc">
            <el-input disabled maxlength="10" v-model="qutaForm.smpl_qpc" placeholder="请填写装箱数"
                      show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="纸箱高度" prop="smpl_box_h">
            <el-input disabled maxlength="10" v-model="qutaForm.smpl_box_h" placeholder="请填写纸箱高度"
                      show-word-limit>
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开版师" prop="smpl_stff_name">
            <el-input maxlength="10" v-model="qutaForm.smpl_stff_name" disabled placeholder="请填写开版师"
                      show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="装袋数" prop="smpl_qpb">
            <el-input maxlength="10" disabled v-model="qutaForm.smpl_qpb" placeholder="请填写装袋数"
                      show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row class="vg_mb_5">
      </el-row> -->
      <el-row class="vg_mb_5">
        <!-- <el-col :md="8">
          <el-form-item label="核价日期" prop="quta_pridate">
            <el-date-picker :disabled="qutaForm.status === 0" v-model="qutaForm.quta_pridate" type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="成交供应商" prop="quta_no">
            <el-select :disabled="qutaForm.status === 0" v-model="qutaForm.supp_id" placeholder="请选择成交供应商" size="small"
              clearable>
              <el-option v-for="item in custList" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
      </el-row>
      <div class="vd_solidDiv"></div>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="打样总报价" prop="quta_quote">
            <el-input
                v-if="qutaForm.status === 0"
                maxlength="10"
                disabled
                v-model="taxPrice"
                placeholder="请填写打样总报价"
                show-word-limit
            >
            </el-input>
            <el-input
                v-if="qutaForm.status !== 0"
                maxlength="10"
                disabled
                v-model="qutaForm.quta_quote"
                placeholder="请填写打样总报价"
                show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样报价工资" prop="quta_qusalary">
            <el-input
                v-if="qutaForm.status === 0"
                maxlength="10"
                v-model="qutaSalaMapsToatl"
                disabled
                placeholder="请填写打样报价工资"
                show-word-limit
            >
            </el-input>
            <el-input
                v-if="qutaForm.status !== 0"
                maxlength="10"
                v-model="qutaForm.quta_qusalary"
                disabled
                placeholder="请填写打样报价工资"
                show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样报价人" prop="quta_quoter_name">
            <el-select filterable v-model="qutaForm.quta_quoter_name" placeholder="请选择打样报价人" size="small"
                       clearable>
              <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name"
                         :value="item.stff_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="棉绳重量" prop="cotton_weight">
            <el-input maxlength="9" v-model="qutaForm.cotton_weight"
              @input="qutaForm.cotton_weight = helper.keepTNum1(qutaForm.cotton_weight)" @change="calcRope" @blur="qutaForm.cotton_weight = helper.calcPrice(qutaForm.cotton_weight,4,10000)" placeholder="请填写棉绳重量"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="棉绳价格" prop="cotton_price">
            <el-input maxlength="10" v-model="qutaForm.cotton_price"
              @input="qutaForm.cotton_price = helper.keepTNum1(qutaForm.cotton_price)"
              @blur="qutaForm.cotton_price = helper.calcPrice(qutaForm.cotton_price,4,10000)"
              @change="calcRope"
              placeholder="请填写棉绳价格"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="断绳" prop="broken_rope">
            <el-input maxlength="10" v-model="qutaForm.broken_rope"
              @input="qutaForm.broken_rope = helper.keepTNum1(qutaForm.broken_rope)"
              @blur="qutaForm.broken_rope = helper.calcPrice(qutaForm.broken_rope,4,10000)"
              @change="calcRope"
              placeholder="请填写断绳"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item prop="cotton_total">
            <div slot="label">
              <span>棉绳总计 </span>
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="棉绳总计=(棉绳重量x棉绳价格+断绳)*1.05">
                <i  slot="reference" class="el-icon-warning-outline"></i>
              </el-popover>
            </div>
            <el-input maxlength="10" v-model="qutaForm.cotton_total"
              disabled placeholder="自动计算"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="气眼价格" prop="eyelet_price">
            <el-input maxlength="10" v-model="qutaForm.eyelet_price"
              @input="qutaForm.eyelet_price = helper.keepTNum1(qutaForm.eyelet_price)"
              @blur="qutaForm.eyelet_price = helper.calcPrice(qutaForm.eyelet_price,4,10000)"
              placeholder="请填写气眼价格"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row> -->
      <!-- <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="采购总核价" prop="quta_price">
            <el-input maxlength="10" v-if="qutaForm.status === 0" disabled v-model="qutaForm.quta_price"
              @input="qutaForm.quta_price = helper.keepTNum1(qutaForm.quta_price)" placeholder="请填写采购总合价"
              show-word-limit>
            </el-input>
            <el-input maxlength="10" v-if="qutaForm.status !== 0" disabled v-model="taxPrice"
              @input="qutaForm.quta_price = helper.keepTNum1(qutaForm.quta_price)" placeholder="请填写采购总合价"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购核价工资" prop="quta_prsalary">
            <el-input v-if="qutaForm.status === 0" maxlength="10" disabled v-model="qutaForm.quta_prsalary"
              @input="qutaForm.quta_prsalary = helper.keepTNum1(qutaForm.quta_prsalary)" placeholder="请填写采购核价工资"
              show-word-limit>
            </el-input>
            <el-input v-if="qutaForm.status !== 0" maxlength="10" disabled v-model="qutaSalaMapsToatl"
              @input="qutaSalaMapsToatl = helper.keepTNum1(qutaSalaMapsToatl)" placeholder="请填写采购核价工资" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购核价人" prop="quta_pricer_name">
            <el-select filterable :disabled="qutaForm.status === 0" v-model="qutaForm.quta_pricer_name" placeholder="请选择采购核价人"
              size="small" clearable>
              <el-option v-for="item in stffType1" :key="item.user_id" :label="item.stff_name" :value="item.stff_name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
      <div class="vd_solidDiv1"></div>
      <el-row class="vg_mb_16">
        <el-col :md="14">
          <QutaParameter @taxPointChangeTotal="taxPointChangeTotal" :qutaForm="qutaForm"
                         @taxPointChange="taxPointChange"></QutaParameter>
        </el-col>
        <el-col :md="9" class="vg_ml_8">
          <QutaProfit :qutaForm="qutaForm" v-if="rateChangeFlag">
            <slot>
              <el-form-item label-width="0" :prop="'quta_profit_rate'">
                <el-input
                    @change="rateChange(1)"
                    maxlength="9"
                    @blur="qutaForm.quta_profit_rate = helper.calcPrice(qutaForm.quta_profit_rate, 4, 10000)"
                    @input="qutaForm.quta_profit_rate = helper.keepTNum1(qutaForm.quta_profit_rate)"
                    v-model="qutaForm.quta_profit_rate"
                    show-word-limit
                    placeholder="暂无利润百分比"
                ></el-input>
              </el-form-item>
            </slot>
          </QutaProfit>
        </el-col>
      </el-row>
      <div>
        <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true">
          <el-tab-pane label="原材料" name="first" :key="'first'">
            <QutaRawMater ref="QutaRawMater" @getTotalPriceTo="getTotalPriceTo" :qutaForm="qutaForm"></QutaRawMater>
          </el-tab-pane>
          <el-tab-pane label="辅助材料" name="second" :key="'second'">
            <QutaAuxiliary ref="QutaAuxiliary" @getTotalPrice="getTotalPrice" :qutaForm="qutaForm"></QutaAuxiliary>
          </el-tab-pane>
          <el-tab-pane label="人工工资" name="third" :key="'third'">
            <QutaOffer ref="QutaOffer" @salaChange="salaChange" :qutaForm="qutaForm"></QutaOffer>
          </el-tab-pane>
          <el-tab-pane label="配件信息" name="fourth" :key="'fourth'">
            <div class="vd_but">
              <el-button type="primary" plain size="small" @click="addPartList()">添加</el-button>
              <el-button type="danger" plain size="small" @click="delPartList()">删除</el-button>
            </div>
            <QutaPartList ref="QutaPartList" :qutaForm="qutaForm"
                          @handleSelectionPart="handleSelectionPart"></QutaPartList>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="vd_solidDiv1"></div> -->
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="qutaForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get, post} from '@api/request';
import {qutaAPI} from '@api/modules/quta';
import {rqtaAPI} from '@api/modules/rqta';
import {smplAPI} from '@api/modules/smpl';
import {userAPI} from '@api/modules/user';
import {imgeAPI} from '@api/modules/imge';
import {suppAPI} from '@api/modules/supp';
import {optnAPI} from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import QutaAuxiliary from './Child/QutaAuxiliary.vue';
import imgPopover from '@/views/component/imgPopover';
import QutaRawMater from './Child/QutaRawMater.vue';
import QutaParameter from './Child/QutaParameter.vue';
import QutaProfit from './Child/QutaProfit.vue';
import QutaOffer from './Child/QutaOffer.vue';
import {BigNumber} from 'bignumber.js';
import QutaPartList from './Child/QutaPartList.vue';
import {json} from 'body-parser';
import {requiredReminder} from "@assets/js/domUtils";

export default {
  name: 'qutaEditMain',
  components: {
    editHeader,
    inputUser,
    QutaAuxiliary, //辅助材料
    QutaRawMater, //原材料
    imgPopover,
    QutaParameter,
    QutaProfit,
    QutaOffer,
    QutaPartList
  },
  data() {
    return {
      rules: {
        smpl_frei_flag: [{required: true, trigger: 'blur', message: ' '}],
        quta_quoter_name: [{required: true, trigger: 'change', message: ' '}]
        // quta_price:[
        //   { required: true, trigger: 'blur' , message: ' '},
        // ],
        // quta_pricer_name:[
        //   { required: true, trigger: 'change' , message: ' '},
        // ],
        // quta_prsalary:[
        //   { required: true, trigger: 'blur' , message: ' '},
        // ],
      },
      rqtaFlag: false,
      qutaForm: {
        imge_id: null,
        smpl_moq: null,
        imge_url: null,
        imge_urlO: null,
        smpl_frei_flag: '1',
        quta_quoter_name: null,
        quta_price: null,
        wagesList: [],
        profitForm: {
          quta_profit_rate: null,
          smplPrice: null,
          priceIndTax: null,
          grossProMargin: null
        }
      },
      isShow: true,
      btn: {},
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      smplType: [
        {id: 1, label: '新款'},
        {id: 2, label: '老款'},
        {id: 3, label: '修改款'}
      ],
      uploadUrl: this.helper.modePrepend(imgeAPI.uploadImage),
      stffForm: {},
      stffType: [],
      stffType1: [],
      qutaCopy: [],
      title: '样品总控表',
      custList: [],
      contryName: null,
      loadingFlag: true,
      beloType: [],
      activeName: 'first',
      qutaSalaMapsToatl: 0,
      wegesForm: {},
      rateChangeFlag: true,
      rawPrice: 0,
      auxilPrice: 0,
      mtrlTotalPrice: 0,
      wagesPrice: 0,
      taxPrice: 0,
      smplMtrlCopy: [],
      qutaAccmtrlListCopy: [],
      partSelectChoose: [],
      partCopy: [],
      smplCopyList: [],
      smplList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getqutaInfo();
      this.getCustList();
      this.getStffType();
      this.getStffType1();
      this.getContry();
      this.checkRqtaFlag();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
          .then(() => {
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
            this.$message({
              type: 'info',
              message: '已撤销编辑!'
            });
          })
          .catch(() => {
          });
    },
    // 检查是否已生成核价单
    checkRqtaFlag() {
      get(rqtaAPI.checkRqtaFlag, {quta_id: this.qutaForm.quta_id})
          .then(res => {
            if (res.data.code === 0) {
              this.rqtaFlag = res.data.data;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 生成核价单
    generateRqta() {
      if (this.rqtaFlag) {
        this.jump('/rqta_edit', {
          perm_id: 124,
          form_id: this.qutaForm.quta_id
        });
        return;
      }
      get(qutaAPI.createRqta, {quta_id: this.qutaForm.quta_id})
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '生成成功'
              });
              this.initData();
              this.jump('/rqta_edit', {
                perm_id: 124,
                form_id: this.qutaForm.quta_id
              });
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.$refs[formName].validate((valid, object) => (valid ? this.saveInfo() : requiredReminder(object)));
            // this.$refs[formName].validate(valid => {
            //   if (valid) {
            //     this.saveInfo();
            //   } else {
            //     console.log('error submit!');
            //     return false;
            //   }
            // });
          })
          .catch(() => {
          });
    },
    // 保存
    saveInfo() {
      let qutaForm = JSON.parse(JSON.stringify(this.qutaForm));
      qutaForm.smpl_deil_time = parseInt(Number(qutaForm.smpl_deil_time) / 1000);
      qutaForm.quta_taxrate_mtrl = qutaForm.wagesList[0].tax_point;
      qutaForm.quta_taxrate_sala = qutaForm.wagesList[1].tax_point;
      qutaForm.quta_sala = {};
      qutaForm.quta_sala.quta_id = qutaForm.quta_id;
      qutaForm.quta_sala.rfid_cut_num = this.qutaForm.quta_sala.rfid_cut_num;
      qutaForm.quta_sala.quta_sala_cut = qutaForm.quta_sala_maps[0].sala;
      qutaForm.quta_sala.rfid_mase_time = this.qutaForm.quta_sala.rfid_mase_time;
      qutaForm.quta_sala.quta_sala_mase = qutaForm.quta_sala_maps[1].sala;
      qutaForm.quta_sala.nedl_phr = this.qutaForm.quta_sala.nedl_phr;
      qutaForm.quta_sala.quta_sala_nedl = qutaForm.quta_sala_maps[2].sala;
      qutaForm.quta_sala.skin_phr = this.qutaForm.quta_sala.skin_phr;
      qutaForm.quta_sala.quta_sala_skin = qutaForm.quta_sala_maps[3].sala;
      qutaForm.quta_sala.cutt_phr = this.qutaForm.quta_sala.cutt_phr;
      qutaForm.quta_sala.quta_sala_cutt = qutaForm.quta_sala_maps[4].sala;
      qutaForm.quta_sala.cott_phr = this.qutaForm.quta_sala.cott_phr;
      qutaForm.quta_sala.quta_sala_cott = qutaForm.quta_sala_maps[5].sala;
      qutaForm.quta_sala.sort_phr = this.qutaForm.quta_sala.sort_phr;
      qutaForm.quta_sala.quta_sala_sort = qutaForm.quta_sala_maps[6].sala;
      qutaForm.quta_sala.pack_phr = this.qutaForm.quta_sala.pack_phr;
      qutaForm.quta_sala.quta_sala_pack = qutaForm.quta_sala_maps[7].sala;
      qutaForm.quta_sala.quta_etc_time = this.qutaForm.quta_sala.quta_etc_time;
      qutaForm.quta_sala.quta_sala_etc = qutaForm.quta_sala_maps[8].sala;
      let quta_sala_time_range = [];
      for (let i = 0; i < qutaForm.quta_sala_maps.length; i++) {
        quta_sala_time_range.push(qutaForm.quta_sala_maps[i].time_range);
      }
      qutaForm.quta_sala.quta_sala_time_range = quta_sala_time_range.join(',');
      // 往下面写就行了
      qutaForm.quta_mtrl_list = this.qutaForm.quta_mtrl_list; //原材料
      qutaForm.quta_accmtrl_list = this.qutaForm.quta_accmtrl_list; //辅料
      qutaForm.quta_stam_num = this.qutaForm.quta_stam_num; //印片用量
      qutaForm.quta_stam_pru = this.qutaForm.quta_stam_pru; //印片单价
      qutaForm.quta_emcd_num = this.qutaForm.quta_emcd_num; //电绣片数
      qutaForm.quta_emcd_pru = this.qutaForm.quta_emcd_pru; //电绣 片数单价
      qutaForm.quta_emcd_pnum = this.qutaForm.quta_emcd_pnum; //电绣 贴数
      qutaForm.quta_emcd_ppru = this.qutaForm.quta_emcd_ppru; //电绣 贴数 单价
      qutaForm.quta_emcd_hnum = this.qutaForm.quta_emcd_hnum; //电绣 针数
      qutaForm.quta_emcd_ppru = this.qutaForm.quta_emcd_ppru; //电绣 针数 单价
      qutaForm.quta_ctn_prsm = this.qutaForm.quta_ctn_prsm; //纸箱 平方价格
      qutaForm.quta_bag_prkg = this.qutaForm.quta_bag_prkg; //塑料袋 公斤价格
      qutaForm.quta_etc_pr = this.qutaForm.quta_etc_pr; //其他辅料费用
      if (!qutaForm.supp_id) {
        qutaForm.supp_id = 0;
      }
      // if (!qutaForm.quta_pricer_name_name) {
      //   qutaForm.quta_pricer_name = 0
      // }
      if (!qutaForm.quta_pridate) {
        qutaForm.quta_pridate = 0;
      } else {
        qutaForm.quta_pridate = this.helper.getNewDate(qutaForm.quta_pridate);
      }
      qutaForm.quta_total = qutaForm.profitForm.priceIndTax;
      // if (qutaForm.status === 0) {
      //   qutaForm.quta_quote = qutaForm.profitForm.priceIndTax
      //   qutaForm.quta_qusalary = qutaForm.wagesList[1].cost_price
      // } else {
      //   qutaForm.quta_price = qutaForm.profitForm.priceIndTax
      //   qutaForm.quta_prsalary = qutaForm.wagesList[1].cost_price
      // }
      this.smplMtrlCopy = this.smplMtrlCopy.filter(item =>
          qutaForm.quta_mtrl_list.every(item1 => item.quta_mtrl_id !== item1.quta_mtrl_id)
      );
      for (let i = 0; i < this.smplMtrlCopy.length; i++) {
        this.smplMtrlCopy[i].destroy_flag = 1;
      }
      this.qutaAccmtrlListCopy = this.qutaAccmtrlListCopy.filter(item =>
          qutaForm.quta_accmtrl_list.every(item1 => item.quta_mtrl_id !== item1.quta_mtrl_id)
      );
      for (let i = 0; i < this.qutaAccmtrlListCopy.length; i++) {
        this.qutaAccmtrlListCopy[i].destroy_flag = 1;
      }
      this.partCopy = this.partCopy.filter(item => qutaForm.quta_part_list.every(item1 => item.quta_part_id !== item1.quta_part_id));
      for (let i = 0; i < this.partCopy.length; i++) {
        this.partCopy[i].destroy_flag = 1;
      }
      qutaForm.quta_mtrl_list = qutaForm.quta_mtrl_list.concat(this.smplMtrlCopy);
      qutaForm.quta_accmtrl_list = qutaForm.quta_accmtrl_list.concat(this.qutaAccmtrlListCopy);
      qutaForm.quta_part_list = qutaForm.quta_part_list.concat(this.partCopy);
      qutaForm.quta_qusalary = this.qutaSalaMapsToatl;
      qutaForm.quta_quote = this.taxPrice;
      post(qutaAPI.editQuta, qutaForm)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.isShow = true;
              this.$emit('isShow', this.isShow);
              this.initData();
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              // this.initData()
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
          .then(() => {
            this.resetForm('qutaForm');
            this.$router.go(-1);
            this.$message({
              type: 'info',
              message: '已取消新增!'
            });
          })
          .catch(() => {
          });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 更改样品工单号
    selectSmplNoChange(e) {
      let tempId = [];
      e.forEach(item => {
        this.smplCopyList.forEach(item1 => {
          if (item === item1.smpl_no) {
            tempId.push(item1.smpl_id);
          }
        });
      });
      this.qutaForm.smpl_ids = tempId.join(',');
      this.qutaForm.smpl_nos = e.join(',');
    },
    // 配件新增
    addPartList() {
      let item = {
        key: this.qutaForm.quta_part_list.length,
        quta_part_name: null,
        quta_part_spec: null,
        quta_part_price: null,
        quta_part_weight: null
      };
      this.qutaForm.quta_part_list.push(item);
    },
    // 配件删除
    delPartList() {
      if (this.partSelectChoose.length === 0) return this.$message.warning('至少选择一条数据');
      let temp = [];
      this.partSelectChoose.forEach(item => temp.push(item.key));
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        this.qutaForm.quta_part_list.splice(j, 1);
      }
      this.qutaForm.quta_part_list.forEach((item, index) => (item.key = index));
      this.$refs.QutaPartList.getTotalPrice();
    },
    // 配件信息勾选框
    handleSelectionPart(val) {
      this.partSelectChoose = JSON.parse(JSON.stringify(val));
    },
    // 原材料计算总价
    getTotalPriceTo(val) {
      let str = JSON.parse(JSON.stringify(this.rawPrice));
      this.rawPrice = Number(this.helper.haveFour(val));
      let num = new BigNumber(0);
      num = num.plus(this.rawPrice).plus(this.auxilPrice);
      this.mtrlTotalPrice = num.toNumber();
      // if (val || val === 0) {
      //   if (Number(val) !== str) {
      this.wagesListChage();
      //   }
      // }
    },
    // 辅助材料计算总价
    getTotalPrice(val) {
      let str = JSON.parse(JSON.stringify(this.auxilPrice));
      this.auxilPrice = Number(this.helper.haveFour(val));
      let num = new BigNumber(0);
      num = num.plus(this.rawPrice).plus(this.auxilPrice);
      this.mtrlTotalPrice = num.toNumber();
      // if (val) {
      //   if (Number(val) !== str) {
      this.wagesListChage();
      // }
      // }
    },
    // 利率变化
    rateChange(val) {
      if (val !== 1) {
        // this.rateChangeFlag = false;
      }
      let profitForm = {};
      let num1 = new BigNumber(1);
      let num2 = new BigNumber(0);
      let num3 = new BigNumber(1);
      num1 = num1.times(this.qutaForm.quta_profit_rate).times(this.wegesForm.totalFour).toNumber();
      num2 = num2.plus(num1).plus(this.wegesForm.totalFour).toNumber();
      num3 = num3.times(num2).div(this.wegesForm.totalTwo).toNumber();
      profitForm.smplPrice = this.helper.haveFour(num1);
      profitForm.priceIndTax = this.helper.haveFour(num2);
      profitForm.grossProMargin = this.helper.reservedDigits(num3);
      this.qutaForm.profitForm = profitForm;
      this.taxPrice = profitForm.priceIndTax;
      this.$nextTick(() => {
        this.rateChangeFlag = true;
      });
    },
    // 参数计算总数
    taxPointChangeTotal(val) {
      this.wegesForm = val;
      this.rateChange(0);
    },
    // 参数信息变化
    taxPointChange(val) {
      this.qutaForm.wagesList[val].tax_point = this.helper.reservedDigits(this.qutaForm.wagesList[val].tax_point);
      this.wagesListChage();
    },
    // 参数信息变化修改值
    wagesListChage() {
      for (let i in this.qutaForm.wagesList) {
        if (i === '0') {
          this.qutaForm.wagesList[i].cost_price = this.helper.haveFour(this.mtrlTotalPrice);
        } else if (i === '1') {
          this.qutaForm.wagesList[i].cost_price = this.helper.haveFour(this.qutaSalaMapsToatl);
        }
        let num1 = new BigNumber(1);
        let num2 = new BigNumber(0);
        num1 = num1.times(this.qutaForm.wagesList[i].cost_price).times(this.qutaForm.wagesList[i].tax_point).toNumber();
        num2 = num2.plus(num1).minus(this.qutaForm.wagesList[i].cost_price).toNumber();
        this.qutaForm.wagesList[i].tax_price = this.helper.haveFour(num1);
        this.qutaForm.wagesList[i].tax_toatl = this.helper.haveFour(num2);
      }
      this.rateChange(0);
    },
    // 报价工资系数单价变化
    salaChange(val) {
      this.qutaSalaMapsToatl = val;
      this.qutaSalaMapsChange();
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1, {dept_ids: '2,17'})
          .then(res => {
            if (res.data.code === 0) {
              let stffType = res.data.data;
              let map = new Map();
              for (let item of stffType) {
                map.set(item.stff_name, item);
              }
              stffType = [...map.values()];
              this.stffType = stffType;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 获取委托人
    getStffType1() {
      get(userAPI.getAllUsersV1, {dept_ids: '10'})
          .then(res => {
            if (res.data.code === 0) {
              let stffType1 = res.data.data;
              let map = new Map();
              for (let item of stffType1) {
                map.set(item.stff_name, item);
              }
              stffType1 = [...map.values()];
              this.stffType1 = stffType1;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, {perm_id: 10009})
          .then(res => {
            if (res.data.code === 0) {
              let beloType = res.data.data.form.optn_cntt_list;
              for (let item of beloType) {
                item.param2 = Number(item.param2);
              }
              this.beloType = beloType;
            }
          })
          .catch(() => {
          });
    },
    // 获取客户国家
    getCustList() {
      get(suppAPI.getSuppsV1)
          .then(res => {
            if (res.data.code === 0) {
              this.custList = res.data.data;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 获取样品工单号
    getSmplList() {
      get(smplAPI.getQutaSmplByRequId, {requ_id: this.qutaForm.requ_id})
          .then(res => {
            if (res.data.code === 0) {
              let list = res.data.data.list;
              let temp1 = this.qutaForm.smpl_ids.split(',');
              let temp2 = this.qutaForm.smpl_nos.split(',');
              let temp = [];
              temp1.forEach((item, index) => {
                let column = {};
                column.smpl_id = Number(item);
                column.smpl_no = temp2[index];
                temp.push(column);
              });
              list.forEach(item => {
                let column = {};
                column.smpl_id = item.smpl_id;
                column.smpl_no = item.smpl_no;
                temp.push(column);
              });
              this.smplCopyList = temp;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getqutaInfo() {
      this.qutaForm.quta_id = this.$route.query.form_id;
      get(qutaAPI.getQutaById, {quta_id: this.qutaForm.quta_id})
          .then(res => {
            if (res.data.code === 0) {
              this.qutaForm = res.data.data.form;
              this.qutaForm.quta_deli_time = Number(this.qutaForm.quta_deli_time) * 1000;
              this.smplMtrlCopy = JSON.parse(JSON.stringify(this.qutaForm.quta_mtrl_list));
              if (this.qutaForm.smpl_deil_time) {
                this.qutaForm.smpl_deil_time = this.qutaForm.smpl_deil_time * 1000;
              }
              if (!this.qutaForm.quta_quoter_name) {
                this.qutaForm.quta_quoter_name = this.$cookies.get('userInfo').stff_name;
              }
              this.qutaForm.smpl_no_list = [];
              JSON.parse(JSON.stringify(this.qutaForm.smpl_nos.split(','))).forEach(item => this.qutaForm.smpl_no_list.push(item));
              this.smplList = JSON.parse(JSON.stringify(this.qutaForm.smpl_nos.split(',')));
              this.qutaForm.smpl_frei_flag = this.qutaForm.smpl_frei_flag + '';
              let urlEng = JSON.parse(JSON.stringify(this.qutaForm.imge_url));
              this.qutaForm.imge_id = 1;
              let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
              this.qutaForm.imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
              this.qutaForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
              this.calcRope();
              this.qutaAccmtrlListCopy = JSON.parse(JSON.stringify(this.qutaForm.quta_accmtrl_list));
              this.partCopy = JSON.parse(JSON.stringify(this.qutaForm.quta_part_list));
              this.qutaForm.quta_part_list.forEach((item, index) => {
                item.key = index;
                item.quta_part_weight = this.helper.haveFour(item.quta_part_weight);
                item.quta_part_price = this.helper.haveFour(item.quta_part_price);
              });
              let wagesList = [];
              for (let i = 0; i < 2; i++) {
                let temp = {
                  id: 0,
                  cost_price: null,
                  tax_point: null,
                  name: null,
                  tax_price: null,
                  tax_toatl: null
                };
                if (i === 0) {
                  temp.id = 0;
                  temp.name = '面料价格';
                  temp.cost_price = this.helper.haveFour(this.mtrlTotalPrice);
                  temp.tax_point = this.qutaForm.quta_taxrate_mtrl;
                  let num1 = new BigNumber(1);
                  let num2 = new BigNumber(0);
                  num1 = num1.times(temp.cost_price).times(temp.tax_point).toNumber();
                  num2 = num2.plus(num1).minus(temp.cost_price).toNumber();
                  temp.tax_price = this.helper.haveFour(num1);
                  temp.tax_toatl = this.helper.haveFour(num2);
                } else if (i === 1) {
                  temp.id = 1;
                  temp.name = '工资';
                  temp.cost_price = this.helper.haveFour(this.qutaSalaMapsToatl);
                  temp.tax_point = this.qutaForm.quta_taxrate_sala;
                  let num1 = new BigNumber(1);
                  let num2 = new BigNumber(0);
                  num1 = num1.times(temp.cost_price).times(temp.tax_point).toNumber();
                  num2 = num2.plus(num1).minus(temp.cost_price).toNumber();
                  temp.tax_price = this.helper.haveFour(num1);
                  temp.tax_toatl = this.helper.haveFour(num2);
                }
                wagesList.push(temp);
              }
              this.qutaForm.wagesList = wagesList;
              let profitForm = {};
              let num1 = new BigNumber(1);
              let num2 = new BigNumber(0);
              let num3 = new BigNumber(1);
              num1 = num1.times(this.qutaForm.quta_profit_rate).times(wagesList[0].tax_price).plus(wagesList[1].tax_price).toNumber();
              num2 = num2.plus(num1).plus(wagesList[0].tax_price).plus(wagesList[1].tax_price).toNumber();
              num3 = num3.times(num2).div(wagesList[0].cost_price).plus(wagesList[1].cost_price).toNumber();
              profitForm.smplPrice = this.helper.haveFour(num1);
              profitForm.priceIndTax = this.helper.haveFour(num2);
              profitForm.grossProMargin = this.helper.reservedDigits(num3);
              this.qutaForm.profitForm = profitForm;
              this.fieldChange();
              this.btn = res.data.data.btn;
              this.stffForm.stff_name = this.qutaForm.stff_name;
              this.stffForm.dept_name = this.qutaForm.dept_name;
              this.stffForm.dept_team_name = this.qutaForm.dept_team_name;
              this.stffForm.user_id = this.qutaForm.user_id;
              this.stffForm.dept_id = this.qutaForm.dept_id;
              this.stffForm.stff_id = this.qutaForm.stff_id;
              setTimeout(() => {
                if (this.qutaForm.create_time === this.qutaForm.update_time) {
                  this.isShow = false;
                  this.$emit('isShow', this.isShow);
                }
                this.getSmplList();
                this.loadingFlag = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 计算棉绳价格  棉绳总计=(棉绳重量x棉绳价格+断绳)*1.05
    calcRope() {
      let num = new BigNumber(1);
      let num1 = new BigNumber(1);
      num = num.times(this.qutaForm.cotton_weight).times(this.qutaForm.cotton_price);
      num = num.plus(this.qutaForm.broken_rope);
      num = num.times(1.05).times(100).toNumber();
      num = Math.round(num);
      num1 = num1.times(num).div(100).toNumber();
      this.qutaForm.cotton_total = this.helper.reservedDigits(num1);
    },
    // 字段计算位数
    fieldChange() {
      this.qutaForm.quta_prsalary = this.helper.haveFour(this.qutaForm.quta_prsalary);
      if (this.qutaForm.quta_pricer_name === 0) {
        this.qutaForm.quta_pricer_name = null;
      }
      if (this.qutaForm.quta_quoter_name === 0) {
        this.qutaForm.quta_quoter_name = null;
      }
      if (this.qutaForm.supp_id === 0) {
        this.qutaForm.supp_id = null;
      }
      if (this.qutaForm.quta_pridate === 0) {
        this.qutaForm.quta_pridate = null;
      } else {
        this.qutaForm.quta_pridate = this.qutaForm.quta_pridate * 1000;
      }
      let num = new BigNumber(0);
      for (let i = 0; i < this.qutaForm.quta_mtrl_list.length; i++) {
        this.qutaForm.quta_mtrl_list[i].quta_mtrl_consume = this.helper.haveFour(this.qutaForm.quta_mtrl_list[i].quta_mtrl_consume, 4);
        this.qutaForm.quta_mtrl_list[i].quta_mtrl_lossrate = this.helper.haveFour(this.qutaForm.quta_mtrl_list[i].quta_mtrl_lossrate, 4);
        this.qutaForm.quta_mtrl_list[i].mtrl_price = this.helper.haveFour(this.qutaForm.quta_mtrl_list[i].mtrl_price, 4);
        this.qutaForm.quta_mtrl_list[i].mtrl_addcost = this.helper.haveFour(this.qutaForm.quta_mtrl_list[i].mtrl_addcost, 4);
        this.qutaForm.quta_mtrl_list[i].quta_contain_lossrate = this.helper.haveFour(
            this.qutaForm.quta_mtrl_list[i].quta_mtrl_consume * this.qutaForm.quta_mtrl_list[i].quta_mtrl_lossrate,
            4
        );
        let num1 = new BigNumber(0);
        num1 = num1
            .plus(this.qutaForm.quta_mtrl_list[i].mtrl_comp_addcost)
            .plus(this.qutaForm.quta_mtrl_list[i].mtrl_prit_addcost)
            .plus(this.qutaForm.quta_mtrl_list[i].mtrl_price);
        num1 = num1
            .times(this.qutaForm.quta_mtrl_list[i].quta_contain_lossrate)
            // .times(this.qutaForm.quta_mtrl_list[i].quta_contain_lossrate)
            .toNumber();
        let num2 = this.helper.haveFour(num1);
        num = num.plus(num2);
      }
      num = num.toNumber();
      this.$refs.QutaRawMater.totalPrice = this.helper.haveFour(num);
      this.rawPrice = num;
      this.qutaForm.quta_accmtrl_list.forEach((item, index) => {
        let num = new BigNumber(1);
        item.key = index;
        item.quta_mtrl_consume = this.helper.haveFour(item.quta_mtrl_consume, 4);
        item.quta_mtrl_lossrate = this.helper.haveFour(item.quta_mtrl_lossrate);
        num = this.helper.haveFour(num.times(item.quta_mtrl_consume).times(item.mtrl_price).times(item.quta_mtrl_lossrate));

        item.mtrl_total_price = num;
      });
      this.qutaForm.smpl_freight = this.helper.haveFour(this.qutaForm.smpl_freight, 4); //运费
      this.qutaForm.quta_ctn_prsm = this.helper.haveFour(this.qutaForm.quta_ctn_prsm, 4); // 纸箱
      this.qutaForm.quta_bag_prkg = this.helper.haveFour(this.qutaForm.quta_bag_prkg, 4); //塑料袋
      this.qutaForm.quta_etc_pr = this.helper.haveFour(this.qutaForm.quta_etc_pr, 4); //其他
      this.qutaForm.smpl_spec = this.helper.reservedDigits(this.qutaForm.smpl_spec);
      this.qutaForm.smpl_skin_weight = this.helper.haveFour(this.qutaForm.smpl_skin_weight);
      this.qutaForm.smpl_weight = this.helper.haveFour(this.qutaForm.smpl_weight);
      this.qutaForm.smpl_box_l = this.helper.reservedDigits(this.qutaForm.smpl_box_l);
      this.qutaForm.smpl_box_w = this.helper.reservedDigits(this.qutaForm.smpl_box_w);
      this.qutaForm.smpl_box_h = this.helper.reservedDigits(this.qutaForm.smpl_box_h);
      this.qutaForm.smpl_bag_l = this.helper.reservedDigits(this.qutaForm.smpl_bag_l);
      this.qutaForm.smpl_bag_w = this.helper.reservedDigits(this.qutaForm.smpl_bag_w);
      for (let i = 0; i < this.qutaForm.quta_sala_maps.length; i++) {
        this.qutaForm.quta_sala_maps[i].time = this.helper.haveFour(this.qutaForm.quta_sala_maps[i].time);
        this.qutaForm.quta_sala_maps[i].sala = this.helper.haveFour(this.qutaForm.quta_sala_maps[i].sala);
      }
      this.printingFilmPriceChange();
      setTimeout(() => {
        this.$refs.QutaPartList.getTotalPrice();
      }, 500);
    },
    // 计算运费
    freightPriceChange(val) {
      let valPrice = new BigNumber(0);
      let num1 = new BigNumber(1);
      let num2 = new BigNumber(0);
      if (this.qutaForm.smpl_frei_flag !== '0') {
        num1 = num1
            .times(this.qutaForm.smpl_box_l)
            .div(100)
            .times(this.qutaForm.smpl_box_w)
            .div(100)
            .times(this.qutaForm.smpl_box_h)
            .div(100)
            .times(this.qutaForm.smpl_freight)
            .div(this.qutaForm.smpl_qpc)
            .toNumber();
        if (Number.isFinite(num1)) {
          valPrice = valPrice.plus(num1).plus(val);
          num1 = this.helper.haveFour(num1);
          this.$refs.QutaAuxiliary.freightPrice = num1;
        } else {
          valPrice = valPrice.plus(val);
        }
      } else {
        valPrice = valPrice.plus(val);
      }
      for (let i = 0; i < this.qutaForm.quta_accmtrl_list.length; i++) {
        let temp = new BigNumber(1);
        temp = temp
            .times(this.qutaForm.quta_accmtrl_list[i].quta_mtrl_consume)
            .times(this.qutaForm.quta_accmtrl_list[i].mtrl_price)
            .times(this.qutaForm.quta_accmtrl_list[i].quta_mtrl_lossrate);
        num2 = num2.plus(temp);
      }
      valPrice = valPrice.plus(num2).toNumber();
      this.auxilPrice = valPrice;
      this.$refs.QutaAuxiliary.totalPrice = this.helper.haveFour(valPrice);
      this.qutaSalaMapsChange();
    },
    // 计算电绣金额
    emcdTotalPriceChnage(val) {
      let valPrice = new BigNumber(0);
      let num1 = new BigNumber(1); //针数
      let num2 = new BigNumber(1); //片数
      let num3 = new BigNumber(1); //贴数
      let num4 = new BigNumber(0);
      if (Number(this.qutaForm.quta_emcd_hnum) % 1000 !== 0) {
        num1 = num1.times(this.qutaForm.quta_emcd_hnum).idiv(1000).plus(1);
      } else {
        num1 = num1.times(this.qutaForm.quta_emcd_hnum).div(1000);
      }
      num1 = num1.times(1000).times(this.qutaForm.quta_emcd_hpru).toNumber();
      num2 = num2.times(this.qutaForm.quta_emcd_num).times(this.qutaForm.quta_emcd_pru).toNumber();
      num3 = num3.times(this.qutaForm.quta_emcd_pnum).times(this.qutaForm.quta_emcd_ppru).toNumber();
      if (num1 >= num2) {
        num4 = num4.plus(num3).plus(num1).toNumber();
      } else {
        num4 = num4.plus(num3).plus(num2).toNumber();
      }
      if (Number.isFinite(num4)) {
        if (Number(this.qutaForm.quta_emcd_price) === 0) {
          valPrice = valPrice.plus(num4).plus(val);
        } else {
          valPrice = valPrice.plus(this.qutaForm.quta_emcd_price).plus(val);
        }
        num4 = this.helper.haveFour(num4);
      } else {
        if (Number(this.qutaForm.quta_emcd_price) === 0) {
          valPrice = valPrice.plus(val);
        } else {
          valPrice = valPrice.plus(val).plus(this.qutaForm.quta_emcd_price);
        }
      }
      if (Number(this.qutaForm.quta_emcd_price) === 0) {
        this.qutaForm.quta_emcd_price = num4;
      }
      valPrice = valPrice.toNumber();
      this.freightPriceChange(this.helper.haveFour(valPrice));
    },
    // 计算塑料袋金额
    emcdNumPriceChange(val) {
      let valPrice = new BigNumber(0);
      if (Number(this.qutaForm.smpl_bag_l) > 0 && Number(this.qutaForm.smpl_bag_w) > 0) {
        let num1 = new BigNumber(1);
        num1 = num1.times(this.qutaForm.smpl_bag_l).div(100).times(this.qutaForm.smpl_bag_w).div(100).times(0.08);
        let num2 = new BigNumber(1);
        num2 = num2.times(num1).times(this.qutaForm.quta_bag_prkg).div(this.qutaForm.smpl_qpb).toNumber();
        if (Number.isFinite(num2)) {
          valPrice = valPrice.plus(num2).plus(val);
          num2 = this.helper.haveFour(num2);
          this.$refs.QutaAuxiliary.bagPrkgPrice = num2;
        } else {
          valPrice = valPrice.plus(val);
          this.$refs.QutaAuxiliary.bagPrkgPrice = '0.0000';
        }
      } else {
        valPrice = valPrice.plus(val);
        this.$refs.QutaAuxiliary.bagPrkgPrice = '0.0000';
      }
      valPrice = valPrice.toNumber();
      this.emcdTotalPriceChnage(this.helper.haveFour(valPrice));
    },
    // 计算纸箱金额
    cartonPriceChange(val) {
      let valPrice = new BigNumber(0);
      if (Number(this.qutaForm.smpl_box_l) > 0 && Number(this.qutaForm.smpl_box_w) > 0 && Number(this.qutaForm.smpl_box_h)) {
        let arr1 = [this.qutaForm.smpl_box_l, this.qutaForm.smpl_box_w, 8];
        let arr2 = [this.qutaForm.smpl_box_h, this.qutaForm.smpl_box_w, 4];
        let num1 = new BigNumber(0);
        num1 = BigNumber.sum(...arr1)
            .div(100)
            .toString();
        let num2 = new BigNumber(0);
        num2 = BigNumber.sum(...arr2)
            .div(100)
            .toString();
        let num3 = new BigNumber(1);
        num3 = num3.times(num1).times(num2).times(this.qutaForm.quta_ctn_prsm).div(this.qutaForm.smpl_qpc).toNumber();
        if (Number.isFinite(num3)) {
          valPrice = valPrice.plus(num3).plus(val);
          num3 = this.helper.haveFour(num3);
          this.$refs.QutaAuxiliary.cartonPrice = num3;
        } else {
          valPrice = valPrice.plus(val);
          this.$refs.QutaAuxiliary.cartonPrice = '0.0000';
        }
      } else {
        this.$refs.QutaAuxiliary.cartonPrice = '0.0000';
        valPrice = valPrice.plus(val);
      }
      valPrice = valPrice.toNumber();
      this.emcdNumPriceChange(this.helper.haveFour(valPrice));
    },
    // 计算印片
    printingFilmPriceChange() {
      let num = new BigNumber(1);
      num = num.times(this.qutaForm.quta_stam_num).times(this.qutaForm.quta_stam_pru).toNumber();
      this.$refs.QutaAuxiliary.printingFilmPrice = this.helper.haveFour(num);
      this.cartonPriceChange(this.helper.haveFour(num));
    },
    // 计算报价工资总数
    qutaSalaMapsChange() {
      let price = new BigNumber(0);
      for (let i = 0; i < this.qutaForm.quta_sala_maps.length; i++) {
        let num1 = new BigNumber(0);
        let num2 = new BigNumber(0);
        let total = new BigNumber(1);
        if (i === 0) {
          num1 = num1.plus(this.qutaForm.quta_sala_maps[i].time_range);
          num2 = num2.plus(this.qutaForm.quta_sala_maps[i].secs).plus(num1);
          total = total.times(num2).times(this.qutaForm.quta_sala_maps[i].sala).toNumber();
        } else {
          num1 = num1.plus(this.qutaForm.quta_sala_maps[i].time_range);
          num2 = num2.plus(this.qutaForm.quta_sala_maps[i].secs).plus(num1);
          let str1 = new BigNumber(1);
          str1 = str1.times(num2).div(60);
          str1 = this.helper.haveFour(str1);
          total = total.times(str1).times(this.qutaForm.quta_sala_maps[i].sala).toNumber();
        }
        // this.qutaForm.quta_sala_maps[i].total = this.helper.haveFour(total)
        this.$set(this.qutaForm.quta_sala_maps[i], 'total', this.helper.haveFour(total));
        price = price.plus(this.qutaForm.quta_sala_maps[i].total);
      }
      price = this.helper.haveFour(price.toNumber());
      this.qutaSalaMapsToatl = price;
      this.$refs.QutaOffer.totalPrice = this.qutaSalaMapsToatl;
      this.getTotalPriceTo(this.rawPrice);
    },

    // 打印
    clickPrint() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.isLoading = true;
            const str = this.$route.query.form_id;
            get(qutaAPI.get_quta_pdf_by_id, {quta_id: str})
                .then(res => {
                  if (res.data.code === 0) {
                    if (process.env.NODE_ENV === 'production') {
                      window.open('/dyjfile' + res.data.data.pdf_url + '.pdf');
                    } else {
                      window.open('/file' + res.data.data.pdf_url + '.pdf');
                    }

                    this.isLoading = false;
                    return;
                  }
                  this.$message.error(res.data.msg);
                  this.isLoading = false;
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                  this.isLoading = false;
                });
          })
          .catch(() => {
          });
    },
    //导出 下载·
    clickExport() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            const str = this.$route.query.form_id;
            get(qutaAPI.get_quta_pdf_by_id, {quta_id: str})
                .then(res => {
                  if (res.data.code === 0) {
                    if (process.env.NODE_ENV === 'production') {
                      this.helper.downloadItem('/dyjfile' + res.data.data.pdf_url + '.pdf', '样品报价单.pdf');
                    } else {
                      this.helper.downloadItem('/file' + res.data.data.pdf_url + '.pdf', '样品报价单.pdf');
                    }
                    return;
                  }
                  this.$message.error(res.data.msg);
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });
          })
          .catch(() => {
          });
    },
    // excel
    clickExport1() {
      this.$confirm('确定下载EXCEL?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            const str = this.$route.query.form_id;
            get(qutaAPI.get_quta_pdf_by_id, {quta_id: str, type: 1})
                .then(res => {
                  if (res.data.code === 0) {
                    this.helper.downloadItemExcel(res.data.data.pdf_url, '报价单.xls');
                    return;
                  }
                  this.$message.error(res.data.msg);
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });
          })
          .catch(() => {
          });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

// .avatar-uploader ::v-deep .el-upload--text {
//   width: 136px;
//   height: 136px;
// }
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_solidDiv {
  height: 1px;
  border: none;
  background-color: #dcdfe6;
  margin: 20px 0 20px 0;
}

.vd_solidDiv1 {
  height: 1px;
  border: none;
  border-top: 1px dashed #dcdfe6;
  margin: 20px 0 20px 0;
}

.vd_but {
  margin-bottom: 10px;
}

::v-deep .el-upload--text {
  height: 200px;
  width: 200px;
}

::v-deep .el-upload-dragger {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vd_rope {
  width: 145px;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
  color: #606266;
}
</style>
